<div class="container">
    <div class="section-title">
        <!-- <h5 class="pre-heading">POWERFUL RENTAL MARKETING</h5> -->
        <h2><span class="highlight">Seamless Lead Integration</span> With Top Property Listing Portals</h2>
        <div class="bar"></div>
        <p>Rent or Sell your properties faster by leveraging our Real Estate CRM. Get all of your leads from top property portals directly in our app and communicate with them via whatsapp/call to lock them in.</p>
    </div>
    <div class="marketing-image">
        <img src="assets/img/marketing.png" alt="image">
    </div>
</div>