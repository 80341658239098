<section class="app-download ptb-100">
    <div class="container">
        <div class="app-download-content">
            <h3>Download Our Apps Today</h3>
            <div class="bar"></div>
            <p>But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account the system and expound the actual teachings of the great explore.</p>
            <div class="app-holder">
                <a routerLink="/"><img src="assets/img/store/1.png" alt="image"></a>
                <a routerLink="/"><img src="assets/img/store/2.png" alt="image"></a>
            </div>
        </div>
    </div>
</section>