<div class="container">
  <div class="section-title">
    <h2>Contact Us</h2>
    <div class="bar"></div>
    <p>Do you have a query?

      or want to get in touch with us?

      Give us a call or email us. You will definitely hear back from us.</p>
  </div>

  <div class="row align-items-center">
    <div class="col-lg-6">
      <div class="contact-form">
        <form id="contactForm" #contactForm="ngForm">
          <div class="row">
            <div class="col-lg-6 col-md-6">
              <div class="form-group">
                <input name="name" type="text" id="name" placeholder="Your Name*" class="form-control" #name="ngModel"
                  [(ngModel)]="contactUsModel.name" required>
                <div *ngIf="name.errors?.required && (name.touched || isSubmitted)" class="text-danger">
                  Name is required.
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-6">
              <div class="form-group">
                <input name="email" type="text" id="email" pattern="[^ @]*@[^ @]*" class="form-control"
                  placeholder="Your Email*" #email="ngModel" [(ngModel)]="contactUsModel.email" required>
              </div>
              <div *ngIf="email.errors?.required && (email.touched || isSubmitted)" class="text-danger">
                Email is required.
              </div>
            </div>

            <div class="col-lg-6 col-md-6">
              <div class="form-group">
                <input name="phone" type="text" id="phone" placeholder="Your Phone*" class="form-control"
                  #phone="ngModel" [(ngModel)]="contactUsModel.phone" required>
                <div *ngIf="phone.errors?.required && (phone.touched || isSubmitted)" class="text-danger">
                  Phone is required.
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-6">
              <div class="form-group">
                <input name="subject" type="text" id="subject" name="subject" placeholder="Subject" class="form-control"
                  #subject="ngModel" [(ngModel)]="contactUsModel.subject" required>
              </div>
              <div *ngIf="subject.errors?.required && (subject.touched || isSubmitted)" class="text-danger">
                Subject is required.
              </div>
            </div>

            <div class="col-lg-12 col-md-12">
              <div class="form-group">
                <textarea name="message" rows="6" id="message" placeholder="Message" class="form-control"
                  #message="ngModel" [(ngModel)]="contactUsModel.message" required required=""></textarea>
              </div>
              <div *ngIf="message.errors?.required && (message.touched || isSubmitted)" class="text-danger">
                Message is required.
              </div>
            </div>

            <div class="col-lg-12 col-md-12">
              <div class="send-btn">
                <button class="default-btn" (click)="SendContactUsDetail(contactForm)">Send Message
                  <span></span></button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="col-lg-6">
      <div class="contact-info">
        <div class="contact-info-content">
          <h3>Contact with us by Your Phone Number or Email Address</h3>
          <h2>
            <a href="tel:+91-7620095560">+91 - 76810 55656</a>
            <span>Or</span>
            <a href="mailto:support@easerent.com">support@easerent.com</a>
          </h2>
          <ul class="social">
            <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
            <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
            <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
            <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>