<section id="screenshots" class="screenshot-area ptb-100">
    <div class="container-fluid">
        <div class="section-title">
            <h2>App Screenshot</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt  labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
        </div>

        <div class="screenshot-slider owl-carousel owl-theme">
            <div class="screenshot-item">
                <div class="image">
                    <img src="assets/img/screenshot/1.png" alt="image">
                </div>
            </div>

            <div class="screenshot-item">
                <div class="image">
                    <img src="assets/img/screenshot/2.png" alt="image">
                </div>
            </div>

            <div class="screenshot-item">
                <div class="image">
                    <img src="assets/img/screenshot/3.png" alt="image">
                </div>
            </div>

            <div class="screenshot-item">
                <div class="image">
                    <img src="assets/img/screenshot/4.png" alt="image">
                </div>
            </div>

            <div class="screenshot-item">
                <div class="image">
                    <img src="assets/img/screenshot/5.png" alt="image">
                </div>
            </div>

            <div class="screenshot-item">
                <div class="image">
                    <img src="assets/img/screenshot/6.png" alt="image">
                </div>
            </div>

            <div class="screenshot-item">
                <div class="image">
                    <img src="assets/img/screenshot/1.png" alt="image">
                </div>
            </div>

            <div class="screenshot-item">
                <div class="image">
                    <img src="assets/img/screenshot/2.png" alt="image">
                </div>
            </div>

            <div class="screenshot-item">
                <div class="image">
                    <img src="assets/img/screenshot/3.png" alt="image">
                </div>
            </div>

            <div class="screenshot-item">
                <div class="image">
                    <img src="assets/img/screenshot/4.png" alt="image">
                </div>
            </div>

            <div class="screenshot-item">
                <div class="image">
                    <img src="assets/img/screenshot/5.png" alt="image">
                </div>
            </div>

            <div class="screenshot-item">
                <div class="image">
                    <img src="assets/img/screenshot/6.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>