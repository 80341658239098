<div class="container">
    <div class="feature-carousel">
        <div class="feature-slides">
            <div>
                <div class="item">
                    <div class="single-feature-slide">
                        <div class="row align-items-center">
                            <div class="col-lg-6 col-md-12">
                                <div class="about-image">
                                    <img src="assets/img/feature1.png" alt="image">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12">
                                <div class="about-content">
                                    <h3>Move-In / Move-Out</h3>
                                    <ul class="icon-features-list">
                                        <li><i class="fas fa-check-circle"></i> Automated Move-In & Move-Out processes</li>
                                        <li><i class="fas fa-check-circle"></i> Capture all pictures of issues during both Move-In & Move-Out</li>
                                        <li><i class="fas fa-check-circle"></i> Full transparency for both Tenants and Owners</li>
                                        <li><i class="fas fa-check-circle"></i> Reduce scope of disagreements/disputes between Tenants/Owners</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="item">
                    <div class="single-feature-slide">
                        <div class="row align-items-center">
                            <div class="col-lg-6 col-md-12">
                                <div class="about-image">
                                    <img src="assets/img/feature2.png" alt="image">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12">
                                <div class="about-content">
                                    <h3>Lead Management</h3>
                                    <ul class="icon-features-list">
                                        <li><i class="fas fa-check-circle"></i> Manage multiple portals leads (Magicbricks, 99acres, Housing.com etc.) </li>
                                        <li><i class="fas fa-check-circle"></i> Call or Whatsapp leads from mobile app</li>
                                        <li><i class="fas fa-check-circle"></i> Track lead status and schedule site visit with reminders and followups</li>
                                        <li><i class="fas fa-check-circle"></i> Get Matched Properties in Real-time and share property details</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="item">
                    <div class="single-feature-slide">
                        <div class="row align-items-center">
                            <div class="col-lg-6 col-md-12">
                                <div class="about-image">
                                    <img src="assets/img/feature3.png" alt="image">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12">
                                <div class="about-content">
                                    <h3>Dashboard</h3>
                                    <ul class="icon-features-list">
                                        <li><i class="fas fa-check-circle"></i> Summary of all your properties and leads</li>
                                        <li><i class="fas fa-check-circle"></i> Preview of all of your Upcoming Site Visits / Follow-Ups</li>
                                        <li><i class="fas fa-check-circle"></i> Manage appointments/schedule via our calendar feature</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="item">
                    <div class="single-feature-slide">
                        <div class="row align-items-center">
                            <div class="col-lg-6 col-md-12">
                                <div class="about-image">
                                    <img src="assets/img/feature4.png" alt="image">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12">
                                <div class="about-content">
                                    <h3>Property Management</h3>
                                    <ul class="icon-features-list">
                                        <li><i class="fas fa-check-circle"></i> Manage all your properties from tip of your finger anytime anywhere</li>
                                        <li><i class="fas fa-check-circle"></i> Online Rent Payment & Security Deposit Collection</li>
                                        <li><i class="fas fa-check-circle"></i> Single place for all Property documents on cloud</li>
                                        <li><i class="fas fa-check-circle"></i> Maintain your properties via Service Request feature</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <button class="prev-arrow slick-arrow">
                <i class="fa fa-chevron-left"></i>
            </button>
            
            <button class="next-arrow slick-arrow">
                <i class="fa fa-chevron-right"></i>
            </button>
        </div>

        <div class="feature-thumbnails">
            <div>
                <div class="item">
                    <div class="img-fill">
                        <img src="assets/img/slide-dot.png">
                    </div>
                </div>

                <div class="item">
                    <div class="img-fill">
                        <img src="assets/img/slide-dot.png">
                    </div>
                </div>

                <div class="item">
                    <div class="img-fill">
                        <img src="assets/img/slide-dot.png">
                    </div>
                </div>
                
                <div class="item">
                    <div class="img-fill">
                        <img src="assets/img/slide-dot.png">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>