import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommomService {
 public config = {};
 public userData: any = {};
 public loading: any;
 private userUpdateSubject: BehaviorSubject<number>;
 public userUpdate: Observable<number>;

 constructor(
   public http: HttpClient,
   public router: Router,
   public spinner: NgxSpinnerService
 ) {
   this.userUpdateSubject = new BehaviorSubject(0);
   this.userUpdate = this.userUpdateSubject.asObservable();
 }

 public userUpdated() {
   this.userUpdateSubject.next(this.userUpdateSubject.getValue() + 1);
 }
 private prepareHeader(headers: HttpHeaders | null): object {
   headers = headers || new HttpHeaders();
   headers = headers.set('Content-Type', 'application/json');
   headers = headers.set('Accept', 'application/json');
   headers = headers.set('Access-Control-Allow-Origin', '*');
   return {
     headers: headers
   };
 }

 private prepareHeaderFormData(headers: HttpHeaders | null): object {
   headers = headers || new HttpHeaders();
   return {
     headers: headers
   };
 }

 get<T>(url: string, headers?: HttpHeaders | null): Observable<T> {
   const expandedHeaders = this.prepareHeader(headers);
   return this.http.get<T>(url, expandedHeaders);
 }

 post(url: string, body: any, headers?: HttpHeaders | null): Observable<any> {
   const expandedHeaders = this.prepareHeader(headers);
   return this.http.post(url, body, expandedHeaders);
 }

 postFormData(url: string, body: any, headers?: HttpHeaders | null): Observable<any> {
   const expandedHeaders = this.prepareHeaderFormData(headers);
   return this.http.post(url, body, expandedHeaders);
 }

 public handleError(error: Response | any) {
   let errMsg: string;
   if (error instanceof Response) {
     const body = error.json() || { error: '' };
     const err = JSON.stringify(body);
     errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
   } else {
     errMsg = error.message ? error.message : error.toString();
   }
   return Observable.throw(errMsg);
 }

 
 eventHandler(event: any) {
   if (event.keyCode == 92 || event.keyCode == 42 || event.keyCode == 40 || event.keyCode == 41 || event.keyCode == 43 || event.keyCode == 63 || event.keyCode == 91 || event.keyCode == 93) {
     return false;
   }
 }

 

 public showLoading() {
   this.spinner.show();
 }

 public hideLoading() {
   setTimeout(() => {
     this.spinner.hide();
   }, 100);
 }

 
}
