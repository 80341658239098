<div class="container">
    <div class="section-title">
        <h2>Testimonials</h2>
        <div class="bar"></div>
    </div>

    <div class="testimonials-slides">
        <div class="client-feedback">
            <div>
                <div class="item">
                    <div class="single-feedback">
                        <div class="icon">
                            <i class="fa fa-quote-left"></i>
                        </div>
                        <p>“ I especially love how easy it makes the application process. No more dealing with paper. They do the dirty work, you get the results you need. Win win! And it doesn’t cost the landlord or property managers a dime. ”</p>
                    </div>
                </div>

                <div class="item">
                    <div class="single-feedback">
                        <div class="icon">
                            <i class="fa fa-quote-left"></i>
                        </div>
                        <p>“ I had so many leads, I couldn’t keep up! EaseRent is over the top easy to use. ”</p>
                    </div>
                </div>

                <div class="item">
                    <div class="single-feedback">
                        <div class="icon">
                            <i class="fa fa-quote-left"></i>
                        </div>
                        <p>“ It's my first and only property management tool. I was sold on the price and features. It meets all of my needs. My tenants also seem to have no issues with it. That's good enough for me!”</p>
                    </div>
                </div>

                <div class="item">
                    <div class="single-feedback">
                        <div class="icon">
                            <i class="fa fa-quote-left"></i>
                        </div>
                        <p>“ Can keep each property cost center separate, sign e leases, works across multiple businesses I run, easy application and screening, multiple properties, great value. ”</p>
                    </div>
                </div>

                <div class="item">
                    <div class="single-feedback">
                        <div class="icon">
                            <i class="fa fa-quote-left"></i>
                        </div>
                        <p>“ Multiple features are interlinked to keep all your information in sync and simple to locate. I received prompt, helpful responses in a timely manner from their friendly support staff. ”</p>
                    </div>
                </div>

                <div class="item">
                    <div class="single-feedback">
                        <div class="icon">
                            <i class="fa fa-quote-left"></i>
                        </div>
                        <p>“ My experience has been seamless and very pleasurable. I've been able to effectively run a large multi-family property with very little learning curve and was able to get fully up to speed within a week or two. ”</p>
                    </div>
                </div>

                <div class="item">
                    <div class="single-feedback">
                        <div class="icon">
                            <i class="fa fa-quote-left"></i>
                        </div>
                        <p>“ I like the fact that I’m able to align all of my properties in one system and it provides me with more insight regarding my day to day operations. ”</p>
                    </div>
                </div>

                <div class="item">
                    <div class="single-feedback">
                        <div class="icon">
                            <i class="fa fa-quote-left"></i>
                        </div>
                        <p>“ I like that EaseRent has the features to meet the major property management duties. A reasonably priced, one-stop shop is what I was looking for. ”</p>
                    </div>
                </div>
            </div>
            
            <button class="prev-arrow slick-arrow">
                <i class="fa fa-chevron-left"></i>
            </button>
            
            <button class="next-arrow slick-arrow">
                <i class="fa fa-chevron-right"></i>
            </button>
        </div>
        
        <div class="client-thumbnails">
            <div>
                <div class="item">
                    <div class="img-fill">
                        <img src="assets/img/client/abhinandan.jpg" alt="client">
                    </div>
                    
                    <div class="title">
                        <h3>Abhinandan Rathore</h3>
                    </div>
                </div>
                
                <div class="item">
                    <div class="img-fill">
                        <img src="assets/img/client/aarif.jpg" alt="client">
                    </div>
                    
                    <div class="title">
                        <h3>Md. Aarif Seikh</h3>
                    </div>
                </div>
                
                <div class="item">
                    <div class="img-fill">
                        <img src="assets/img/client/priyanka.jpg" alt="client">
                    </div>
                    
                    <div class="title">
                        <h3>Priyanka Sharma</h3>
                    </div>
                </div>
                
                <div class="item">
                    <div class="img-fill">
                        <img src="assets/img/client/manwinder.jpg" alt="client">
                    </div>
                    
                    <div class="title">
                        <h3>Manwinder Singh</h3>
                    </div>
                </div>
                
                <div class="item">
                    <div class="img-fill">
                        <img src="assets/img/client/priyasha.jpg" alt="client">
                    </div>
                    
                    <div class="title">
                        <h3>Priyasha Choudhary</h3>
                    </div>
                </div>
                
                <div class="item">
                    <div class="img-fill">
                        <img src="assets/img/client/ashwini.jpg" alt="client">
                    </div>
                    
                    <div class="title">
                        <h3>Ashwini Vishwanathan</h3>
                    </div>
                </div>
                
                <div class="item">
                    <div class="img-fill">
                        <img src="assets/img/client/abhilasha.jpg" alt="client">
                    </div>
                    
                    <div class="title">
                        <h3>Abhilasha Rajanandini</h3>
                    </div>
                </div>
                
                <div class="item">
                    <div class="img-fill">
                        <img src="assets/img/client/vishwarajan.jpg" alt="client">
                    </div>
                    
                    <div class="title">
                        <h3>Vishwaranjan Shetty</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>