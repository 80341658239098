import { Component, OnInit } from '@angular/core';

@Component({
  // selector: 'app-privacy-terms',
  templateUrl: './privacypolicy.component.html',
  styleUrls: ['./privacypolicy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
