<div class="container pb-100">

    <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
            <div class="about-content">
                <h1 style="font-size: 65px;">Our <span class="highlight" style="text-transform: none;">iOS App</span> is well under way</h1>
                &nbsp;
                <h3>You will be able to find us on the app store soon.</h3>
            </div>
        </div>

        
        <div class="col-lg-6 col-md-12">
            <div class="comingsoon-image">
                <img src="assets/img/mobile.png" alt="image">
            </div>
        </div>

    </div>
    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/1.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/2.png" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/3.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/4.svg" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/5.png" alt="image"></div>
    </div>
</div>