<section class="footer-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo">
                        <img src="assets/img/easerent-logo.png" alt="EaseRent" >
                    </a>
                    <!-- <p style="text-align: justify;"></p> -->
                    <ul class="social-list">
                        <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-footer-widget pl-5">
                    <h3>Company</h3>

                    <ul class="list footer-nav">
                        <li><a href="#about">About Us</a></li>
                        <li><a href="#services">Our Services</a></li>
                        <li><a href="#howitworks">How it works</a></li>
                        <li><a href="#contact">Contact Us</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Other Links</h3>

                    <ul class="list">
                        <li><a routerLink="/privacypolicy">Privacy Policy</a></li>
                        <li><a routerLink="/termsconditions">Terms & Conditions</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Get Our App</h3>
                    
                    <ul class="footer-holder">
                        <li><a href="https://play.google.com/store/apps/details?id=com.easerent" target="_blank"><img src="assets/img/store/1.png" alt="image"></a></li>
                        <li><a routerLink="/comingsoon"><img src="assets/img/store/2.png" alt="image"></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="copy-right">
    <div class="container">
        <div class="copy-right-content">
            <p>
                <i class="far fa-copyright"></i> 2022 Easerent. All Rights Reserved by <a href="#" target="_blank">easerent.com</a></p>
        </div>
    </div>
</div>

<div class="go-top">
    <i class="fa fa-chevron-up"></i>
    <i class="fa fa-chevron-up"></i>
</div>