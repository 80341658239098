<div class="container">
    <!-- <div class="section-title">
        <h2>About Our App</h2>
        <div class="bar"></div>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt  labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
    </div> -->

    <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
            <div class="about-image">
                <img src="assets/img/about-img.png" alt="About Easerent">
            </div>
        </div>
        <div class="col-lg-6 col-md-12">
            <div class="about-content">
                <h5 class="pre-heading">WHO WE ARE </h5>
                <h3>About <span class="highlight">EaseRent</span></h3>
                <div class="bar"></div>
                <p>EaseRent came into existence when we saw that there was 
                    a gap in the current property rental management 
                    business. There are lots of sites available to list your 
                    properties for renting, however there is no end-to-end 
                    solution beyond that to manage the entire experience for 
                    Property Owner, Tenant and Property Manager (Broker) 
                    which includes move-in/move-out, automated rental 
                    payments and security deposit collection, bill payments, 
                    central place to store all documents and finally a way to 
                    collect brokerage fees.  
                </p>
                <div id="collapseOne" class="collapse" data-bs-parent="#faqAccordion">
                    <p>EaseRent has been created to exactly address all of the
                        above gaps and is a platform for Property Owner, Tenant
                        and Property Manager (Broker) to interact with each other
                        and smoothen out the entire rental experience. With
                        above listed features, everyone can get the visibility and
                        transparency into all the transactions being accessible
                        from a single portal and thereby reducing stress and
                        headaches of gathering disjointed information.</p>
                </div>
                <div class="about-btn">
                    <a type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" class="default-btn">Read More<span></span></a>
                </div>
            </div>
        </div>
    </div>
</div>