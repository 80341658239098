import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
import { HomeThreeComponent } from './components/pages/home-three/home-three.component';
import { BlogPageComponent } from './components/pages/blog-page/blog-page.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { ComingSoonComponent } from './components/pages/comingsoon/comingsoon.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-terms/privacypolicy.component'; 
import { TermsConditions } from './components/pages/privacy-terms/termsconditions.component';
import { NestguruPrivacyComponent } from './components/pages/nestguru-privacy/nestguru-privacy.component';

const routes: Routes = [
    {path: '', component: HomeOneComponent},
    {path: 'home-two', component: HomeTwoComponent},
    {path: 'home-three', component: HomeThreeComponent},
    {path: 'blog', component: BlogPageComponent},
    {path: 'single-blog', component: BlogDetailsComponent},
    {path: 'comingsoon', component: ComingSoonComponent},
    {path: 'privacypolicy', component: PrivacyPolicyComponent},
    {path: 'termsconditions', component: TermsConditions},
    {path: 'nestguruprivacypolicy', component: NestguruPrivacyComponent}
    
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }