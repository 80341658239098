import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nestguru-privacy',
  templateUrl: './nestguru-privacy.component.html',
  styleUrls: ['./nestguru-privacy.component.scss']
})
export class NestguruPrivacyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
