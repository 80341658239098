import { Component, Input, OnInit } from '@angular/core';
import { NgForm  } from '@angular/forms';
import { Location } from '@angular/common';
import { CommomService } from 'src/app/commom.service';
import { ToastrService } from 'ngx-toastr';




@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],

})
export class ContactComponent implements OnInit {

  @Input() close: () => void;
  contactUsModel: any = {};
  isSubmitted = false;
  
  angularPath: any;
  hostname: string;
  hostUrl: string;
  url: string;
  baseUrl:any;
  port: any;
 
  constructor(
    private location: Location,
    public commonService: CommomService,
    private toastr: ToastrService
    ) {}

  ngOnInit() {
    this.getBaseUrl();
  }

  getBaseUrl(){
    this.angularPath = this.location.path();
    this.hostname = window.location.hostname;
    this.port= window.location.port;
    this.hostUrl = window.location.href;
    this.hostUrl = this.hostUrl.substring(0,this.hostUrl.length - 1);
    if(this.hostname === "localhost") {
      this.url = "http://localhost:8000";
    }else if(this.hostname === "www.easerent.com") {
      this.url = "https://app.easerent.com";
    }else if(this.hostname === "stag.easerent.com") {
      this.url = "https://stagapp.easerent.com";
    }
    else {
      this.url = "https://" + this.hostname;
    }
  }

  SendContactUsDetail(contactForm: NgForm){ 
    console.log(this.contactUsModel)  
    this.baseUrl = this.url + '/api/EaseRent/addContactUs';
    if (contactForm.invalid) {
      this.isSubmitted = true;
      return;
    }

    this.isSubmitted = false;
    this.commonService.showLoading();
    this.commonService.post(this.baseUrl, this.contactUsModel).subscribe((res) => {
      if (!!res) {
       this.toastr.success("Success", res.message)
      } 
      contactForm.reset();
      this.commonService.hideLoading();
      console.log('this.close');
      if (this.close) {
        this.close();
      }
    });
  }
  closed() {
    this.close();
  }
}
