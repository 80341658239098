<div class="container">
    <!-- <div class="section-title">
        <h2>About Our App</h2>
        <div class="bar"></div>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt  labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
    </div> -->

    <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
            <div class="about-image">
                <img src="assets/img/landlord.png" alt="image">
            </div>
        </div>

        <div class="col-lg-6 col-md-12">
            <div class="about-content">
                <h5 class="pre-heading">PROFESSIONAL RENTAL MANAGEMENT</h5>
                <h3>Be <span class="highlight">The Best Landlord</span> Your Tenant Has Ever Had</h3>
                <div class="bar"></div>
                <ul class="icon-features-list">
                    <li><i class="fas fa-check-circle"></i> <strong>For Landlords - </strong> Stay organized and look professional in the eyes of your tenant, with everything in one place.</li>
                    <li><i class="fas fa-check-circle"></i> <strong>For Your Tenants - </strong> One tool for applying, paying rent, and requesting maintenance.</li>
                    <li><i class="fas fa-check-circle"></i> <strong>For Both - </strong> Handle paperwork, payments, communication, and maintenance all together to eliminate confusion.</li>
                </ul>
                <div class="about-btn">
                    <a routerLink="/" class="default-btn">Free Landlord Signup<span></span></a>
                </div>
            </div>
        </div>
    </div>
</div>