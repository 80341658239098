<div class="container">

    <div class="row align-items-center">
        <div class="col-lg-6">
            <h3>One stop shop for all property management needs</h3>

            <div class="tab search-property-tab">
                <ul class="tabs">
                    <li><a href="#">Sale</a></li>
                    <li><a href="#">Rent</a></li>
                </ul>

                <div class="tab_content">
                    <div class="tabs_item">
                        <div class="search-property-form">
                            <form id="saleSearchForm" [formGroup]="saleForm" novalidate>
                                <div class="row">
                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <select name="propertyType" id="propertyType" class="form-control"
                                                formControlName="propertyType" placeholder="--Select property type--">
                                                <option [ngValue]="undefined" selected disabled>--Select property type--
                                                </option>
                                                <option [ngValue]="type" *ngFor="let type of propertiesType"> {{type}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <select class="form-control" required #salePrice
                                                (change)="onSalePriceChange(salePrice.value)" formControlName="price">
                                                <option [ngValue]="undefined" selected disabled>--Price Range--</option>
                                                <option [ngValue]="price" *ngFor="let price of salePriceRange">
                                                    {{price}} </option>
                                            </select>
                                            <input [value]="saleFromPrice" type="text" name="fromPrice" id="fromPrice"
                                                class="form-control" hidden>
                                            <input [value]="saleToPrice" type="text" name="toPrice" id="toPrice"
                                                class="form-control" hidden>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <select name="bhk" id="bhk" class="form-control" formControlName="bhk">
                                                <option [ngValue]="undefined" selected disabled>--Select BHK--</option>
                                                <option [ngValue]="bhk" *ngFor="let bhk of bhkList"> {{bhk}} </option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-6">
                                        <div class="ng-autocomplete" style="width: 100%;">
                                            <ng-autocomplete [data]="saleCityData" [searchKeyword]="keyword"
                                                placeholder="Select city" (selected)='selectSaleCity($event)'
                                                (inputChanged)='onChangeSearchSale($event)'
                                                (inputFocused)='onFocusedSale($event)'
                                                (inputCleared)='onClearedSale($event)' [itemTemplate]="itemTemplate"
                                                [notFoundTemplate]="notFoundTemplate" formControlName="city">
                                            </ng-autocomplete>

                                            <ng-template #itemTemplate let-item>
                                                <a [innerHTML]="item.name"></a>
                                            </ng-template>

                                            <ng-template #notFoundTemplate let-notFound>
                                                <div [innerHTML]="notFound"></div>
                                            </ng-template>
                                        </div>

                                        <div class="form-group">
                                            <input [value]="citySale" type="text" name="city" id="city"
                                                class="form-control" required style="opacity: 0; height: 0;">
                                        </div>
                                        <div *ngIf="saleForm.controls['city']?.errors?.required && (saleForm.controls['city'].touched || isSaleSubmitted)" class="alert alert-danger">
                                            <div>
                                                City is Required
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group" style="display: none;">
                                        <input type="text" name="type" id="type" class="form-control" value="Sale">
                                    </div>

                                    <div class="col-lg-12 col-md-12">
                                        <div class="send-btn">
                                            <button (click)="findSaleSearchProperties()"
                                                class="default-btn">Search<span></span></button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div class="tabs_item">
                        <div class="search-property-form">
                            <form id="rentSearchForm" [formGroup]="rentForm">
                                <div class="row">
                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <select name="propertyType" id="propertyType" class="form-control"
                                                formControlName="propertyType">
                                                <option [ngValue]="undefined" selected disabled>--Select property type--
                                                </option>
                                                <option [value]="type" *ngFor="let type of propertiesType"> {{type}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <select class="form-control" required #rentPrice
                                                (change)="onRentPriceChange(rentPrice.value)" formControlName="price">
                                                <option [ngValue]="undefined" selected disabled>--Price Range--</option>
                                                <option [value]="price" *ngFor="let price of rentPriceRange"> {{price}}
                                                </option>
                                            </select>
                                            <input [value]="rentFromPrice" type="text" name="fromPrice" id="fromPrice"
                                                class="form-control" placeholder="Price from" hidden>
                                            <input [value]="rentToPrice" type="text" name="toPrice" id="toPrice"
                                                class="form-control" placeholder="Price from" hidden>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <select name="bhk" id="bhk" class="form-control" formControlName="bhk">
                                                <option [ngValue]="undefined" selected disabled>--Select BHK--</option>
                                                <option [value]="bhk" *ngFor="let bhk of bhkList"> {{bhk}} </option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-6">
                                        <div class="ng-autocomplete" style="width: 100%;">
                                            <ng-autocomplete [data]="rentCityData" [searchKeyword]="keyword"
                                                placeholder="Select city" (selected)='selectRentCity($event)'
                                                (inputChanged)='onChangeSearchRent($event)'
                                                (inputFocused)='onFocusedRent($event)'
                                                (inputCleared)='onClearedRent($event)' [itemTemplate]="itemTemplate"
                                                [notFoundTemplate]="notFoundTemplate" formControlName="city">
                                            </ng-autocomplete>

                                            <ng-template #itemTemplate let-item>
                                                <a [innerHTML]="item.name"></a>
                                            </ng-template>

                                            <ng-template #notFoundTemplate let-notFound>
                                                <div [innerHTML]="notFound"></div>
                                            </ng-template>
                                        </div>
                                        <div class="form-group">
                                            <input [value]="cityRent" type="text" name="city" id="city"
                                                class="form-control" required style="opacity: 0; height: 0;">
                                        </div>
                                        <div *ngIf="isRentSubmitted" class="alert alert-danger">
                                            <div>
                                                City is Required
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group" style="display: none;">
                                        <input type="text" name="type" id="type" class="form-control" value="Rent">
                                    </div>

                                    <div class="col-lg-12 col-md-12">
                                        <div class="send-btn">
                                            <button (click)="findRentSearchProperties()"
                                                class="default-btn">Search<span></span></button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-6">

        </div>
    </div>
</div>