import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  angularPath: any;
  url: any;
  hostname: any;
  hostUrl: string;

  constructor(private location: Location) { }

  ngOnInit() {
    this.getLoginUrl();
  }

  collapsed = true;
  toggleCollapsed(): void {
    this.collapsed = !this.collapsed;
  }

  getLoginUrl() {
    this.angularPath = this.location.path();
    this.hostname = window.location.hostname;
    this.hostUrl = window.location.href;
    this.hostUrl = this.hostUrl.substring(0, this.hostUrl.length - 1);
    if (this.hostname === "localhost") {
      this.url = "http://localhost:4200";
    } else if (this.hostname === "www.easerent.com") {
      this.url = "https://app.easerent.com";
    } else if (this.hostname === "stag.easerent.com") {
      this.url = "https://stagapp.easerent.com";
    }
    else {
      this.url = "https://" + this.hostname;
    }
  }
}
