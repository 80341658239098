<div class="container">
    <div class="section-title">
        <h5 class="pre-heading">WATCH THE VIDEO</h5>
        <h2>How It Works ?</h2>
        <div class="bar"></div>
        <p>Watch the video to get an idea how EaseRent Rental Management Application works.</p>
    </div>
    <!-- <div class="video-content">
        <h3>How it Works?</h3>
        <a href="https://www.youtube.com/watch?v=Kk5LMIoLG34&ab" class="video-btn popup-youtube"><i class="fas fa-play"></i></a>
    </div> -->
    <div class="iframe-container">
        <iframe class="responsive-iframe" src="https://www.youtube.com/embed/Kk5LMIoLG34" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
</div>